<template>
  <v-container id="about-us" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="mt-0" shaped>
          <v-card-title class="success">
            <v-btn class="mx-1" text to="/">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-spacer></v-spacer> 
            <div class="display-2 white--text" color="white">Contact Us</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <br />
            <ul>
              <li>
                <b> LEDIP (Negosyo Center - Province of Iloilo) </b>
                <p>Email:loiloinvest@iloilo.gov.ph</p>
                <p>Phone: (033) 320 2095</p>
              </li>
              <li>
                <b>PAO (Provincial Agriculture Office)</b>
                <p>Phone:(033) 337 7760</p>
              </li>
              <li>
                <b>ICTMO (Province of Iloilo - MIS)</b>
                <p>Phone: (033) 328 7900</p>
              </li>
              <li>
                <b>Office of the Municipal Mayor, Mina Iloilo</b>
                <p> (033) 523-56-88</p>
              </li>
              <li>
                <b>Office of the Municipal Agriculturist, Mina Iloilo</b>
                <p>(033) 530-98-45</p>
              </li>
            </ul>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>
